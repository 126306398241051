<template>
  <el-container class="shopList ">
    <el-header class="box add">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>标记企业管理</el-breadcrumb-item>
        <el-breadcrumb-item>标记企业列表</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-form :inline="true" :model="form" class="demo-form-inline">
      <el-col :span="4" class="mr10">
        <el-form-item size="medium">
          <el-input
                  placeholder="企业名称"
                  v-model="form.company_name"
                  class="input-with-select"
          >
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6" class="tl mr10">
        <el-form-item size="medium">
          <el-button
                  type="primary"
                  @click="search"
                  class="general_bgc general_border cfff"
          >查询</el-button
          >
          <el-button type="primary" size="medium" @click="Add"
          >新增标记企业</el-button
          >
          <el-button class size="medium" @click="$router.back()">返回</el-button>
        </el-form-item>
      </el-col>
    </el-form>
      <el-table
              :data="tableData"
              style="width: 100%">
        <el-table-column
                type="index"
                label="序号"
                width="55">
        </el-table-column>
        <el-table-column
                prop="company_name"
                label="企业名称"
                min-width="100">
        </el-table-column>
        <el-table-column
                prop="address"
                label="企业地址"
                min-width="100">
        </el-table-column>
        <el-table-column
                prop="credit_code"
                label="社会信用代码"
                min-width="100">
        </el-table-column>
        <el-table-column
                prop="phone"
                label="手机号"
                min-width="80">
          <template slot-scope="scope">
            <el-input
                    placeholder="请输入内容"
                    v-model="scope.row.phone"
                    @blur="change(scope.row)"
                    clearable>
            </el-input>
          </template>

        </el-table-column>
        <el-table-column label="操作" fixed="right" width="80px" align="center">
          <template slot-scope="scope">
            <el-button type="text" class="disib red" size="mini" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    <div class="i-page fr disib mt20">
      <el-pagination
              background
              :page-size="10"
              layout="total,prev, pager, next"
              :total="total"
              :current-page="currentPage"
              @current-change="currentChange"
      >
      </el-pagination>
    </div>
    <el-dialog :title="title" :visible.sync="dialogAddgsVisible"  @close="closeDialogAddgsVisible">
      <el-form :inline="true" :model="form" class="demo-form-inline">
        <el-col :span="6" class="mr10">
          <el-form-item size="medium">
            <el-input
                    placeholder="企业名称"
                    v-model="form2.company_name"
                    clearable
                    class="input-with-select"
                    @clear="onSubmit(form2.company_name)"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6" class="mr10">
          <el-form-item size="medium">
            <el-input
                    placeholder="社会信用代码"
                    v-model="form2.credit_code"
                    class="input-with-select"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4" class="tl mr10" >
          <el-form-item size="medium">
            <el-button type="primary" size="medium" @click="search1"
            >查询</el-button
            >
            <el-button type="primary" size="medium" @click="submit"
            >添加</el-button
            >
          </el-form-item>
        </el-col>
      </el-form>
      <el-table
              :data="allCompany"
              tooltip-effect="dark"
              style="width: 100%"
              @selection-change="handleSelectionChange">
        <el-table-column
                prop="company_name"
                type="selection"
                width="55">
        </el-table-column>
        <el-table-column
                prop="company_name"
                label="企业名称"
                min-width="100">
        </el-table-column>
        <el-table-column
                prop="address"
                label="企业地址"
                min-width="100">
        </el-table-column>
        <el-table-column
                prop="credit_code"
                label="社会信用代码"
                min-width="100">
        </el-table-column>
      </el-table>
      <el-pagination
              background
              :page-size="10"
              layout="total,prev, pager, next"
              :total="allTotal"
              :current-page="allCurrentPage"
              @current-change="allCurrentChange"
      >
      </el-pagination>
    </el-dialog>
  </el-container>
</template>
<script>
import axios from '../../../util/api'
import { session, aa } from '../../../util/util'
export default {
  data () {
    return {
      tableData:[],
      allCompany:[],
      form:{
        company_name:"",
        pager_offset: "0",
        pager_openset: "10",
        credit_code:"",
        signDepartment:'',
      },
      form2:{
        company_name:"",
        pager_offset: "0",
        pager_openset: "10",
        credit_code:"",
        signDepartment:'',
      },
      title:"标记企业",
      currentPage: 1,
      allCurrentPage:1,
      total:0,
      allTotal:0,
      dialogAddgsVisible:false,
      companyId:[],
    }
  },
  mounted () {
    this.form.signDepartment = session.get("signDepartment").id;
    this.form2.signDepartment = session.get("signDepartment").id;
    this.getData(this.form);
    this.getAllData( {company_name:this.form2.company_name} );
  },
  methods: {
    getData(form){
      axios.get("/pc/company/signList/1",form).then(res=>{
        res = res.data;
        if (res){
          this.tableData = res.company_sign_list;
          this.total = res.pager_count;
        }
      });
    },
    getAllData(form){
      axios.get("/pc/company/signList/2",form).then(res=>{
        res = res.data;
        if (res){
          this.allTotal = res.pager_count;
          this.allCompany = res.company_list;
        }
      });
    },
    Add(){
      this.dialogAddgsVisible = true;
    },
    onSubmit(){
      this.form2.name = "";
      this.form.name = "";
    },
    search(){
      this.getData(this.form);
    },search1(){
      this.getAllData({company_name:this.form2.company_name});
    },
    change(row){
      let param = {company_id:row.company_id,
                  departmentSignId:this.form.signDepartment,
                  phone:row.phone}
      axios.post("/pc/company/addPhone",param).then(res=>{
            res = res.data;
      })
    },
    handleDelete(row){
      // let { user_id } = row;
      axios.delete("/pc/company/deleteSign",{
        departmentSignId:this.form.signDepartment,
        company_id:String(row.company_id)
      }).then(response => {
        this.getData(this.form);
      });
    },
    submit(){
      let param = {company:JSON.stringify(this.companyId),signDepartment:this.form.signDepartment};
      axios.post("/pc/company/signAdd",param).then(res=>{
        this.dialogAddgsVisible =false;
        this.getData(this.form);
        this.getAllData({company_name:this.form2.company_name});
      })
    },
    closeDialogAddgsVisible(){
      this.dialogAddgsVisible = false;
    },

    currentChange(page) {
      this.currentPage = page;
      this.form.pager_offset = String((page - 1) * 10);
      this.getData(this.form);
    },
    allCurrentChange(page) {
      this.allCurrentPage = page;
      this.form2.pager_offset = String((page - 1) * 10);
      this.getAllData({company_name:this.form2.company_name});
    },
    handleSelectionChange(val){
      let company = val;
      company.map((item,index)=>{
        this.companyId[index] = item.company_id;
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.shopList {
  overflow-x: hidden;
  .add {
    display: flex;
    top: 0px;
    left: 30px;
    justify-content: space-between;
    align-items: center;
  }
  .active {
    background: #2ec68a;
    border-color: #2ec68a;
    color: #ffffff;
  }
  .list_tab {
    padding: 10px;
    background-color: #f5f7fa;
    .list_tag {
      right: -10px;
      padding: 2px 6px;
      border-radius: 10px;
      top: -10px;
    }
  }

  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }
  /deep/.el-table .aa .cell {
    display: flex;
    justify-content: center;
  }
}
</style>
